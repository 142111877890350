import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Head from "../components/head";
import * as styles from "./../styles/modules/aiArticle.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const GettingPastTomorrow = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Getting Past Tomorrow`;

  return (
    <Layout location={location} title={siteTitle}>
      <Head title="Getting Past Tomorrow" />
      <div className={styles.aiArticle}>
        <section className={styles.first}>
          <div className={styles.container}>
            <h1>
              <span>G</span>ETTING <span>P</span>AST <span>T</span>OMORROW
            </h1>
            <h2>
              A snapshot of NZ journalism and PR as they face AI transformation
            </h2>
            <h4>A Communication Sector Report</h4>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2>Overview: Two Sectors in Transition</h2>
            <h5>
              Facing change, media and PR are trying to sift the opportunities
              from the challenges
            </h5>
            <p>
              <strong> Gradually, then suddenly </strong>
            </p>
            <p>
              For a sense of AI’s pace, you need only look at the images on this
              page captured by American business professor and international
              writer on AI, Ethan Mollick.
              <br />
              <br />
              Giving an identical prompt at regular intervals to four evolving
              versions of the AI tool, Midjourney. Mollick asked for a fashion
              shoot inspired by Van Gogh. The results progressed from
              “laughable” to impressive. Mollick felt the fourth still needed a
              human touch, but there was no doubting AI’s evolution and
              continuing ability to surprise.
              <br />
              <br />
              Few technologies have promised as much as AI. Globally, the
              transition to business use remains a work in progress, but as a
              knowledge worker if you are not using it already experts say you
              soon will be - or feel uncomfortably left behind.
              <br />
              <br />
              Every sector is finding its own way. And, for the two at the
              centre of this report, journalism and public relations, doing so
              one step at a time.
            </p>
            <blockquote>
              “As a knowledge worker, if you are not using AI already you soon
              will be - or feeling uncomfortably left behind.”
            </blockquote>

            <StaticImage src="../images/ai-article/mj.png" alt="" />
            <small>
              Every picture tells a story. Four evolving versions of Midjourney
              respond to an identical prompt between April 2022 and December
              2023. Reproduced with permission from Ethan Mollick.
            </small>
          </div>
        </section>
        <section className={styles.info}>
          <div className={styles.container}>
            <h2>Sifting Opportunities from Challenges</h2>
            <h5>1994 all over again</h5>
            <p>
              This report was prompted partly by hearing Justin Flitter, founder
              of AI New Zealand, describe AI’s ability to produce an annual
              report in hours rather than months. For practical reasons, it
              turns out acting on this is still some distance away. But the
              implications are clear, as is the need to work out what is coming
              next.
              <br />
              <br /> “Traditional” AI, has revolutionised areas like medical
              research by crunching data. But generative AI, like ChatGPT or
              Midjourney which can create content, gets a lot closer to both
              sectors in having language, images and communication at its core.
              <br />
              <br /> Among leaders and practitioners in both sectors scepticism
              about AI hype remains real. Yet most are optimistic about its
              impact. Die in a ditch naysayers appear to have done just that.
            </p>
            <blockquote>
              “The bit people are missing is that we have been given
              intelligence in a non-human form. This is a really big deal…It’s
              like going from a Model T Ford to a Tesla in five years.”
              <small>
                - Scott McLiver, PwC’s Asia Pacific Leader in Generative AI
              </small>
            </blockquote>

            <p>
              There is concern about job losses, although few see these as
              imminent or likely. The focus is on efficiency not cost.
              <br />
              <br /> Ethical concerns turn largely on ensuring transparency on
              how AI is being used. As an industry, journalism will push harder
              for regulatory support. Public relations may need new ways of
              showing and charging for value.
              <br />
              <br />
              Madeline Newman, the Executive Director of AI Forum NZ, says AI
              researchers at New Zealand universities are world standing. But in
              business, most sources agree we are still getting up to pace.
              Scott McLiver, PwC’s Asia Pacific AI leader, suggests we have been
              here before, in 1994, and wondering what the Internet would bring.{" "}
              <br />
              <br />
              “There was a lot of speculation and expectation. But it took a
              while for the likes of Jeff Bezos to come along and show how it
              could be applied and change our world.
              <br />
              <br /> “The bit people are missing is that we have been given
              intelligence in a non-human form. This is a really big deal. Up to
              now no matter how good our tooling, the cognitive piece has always
              been human. In addition to the emergence of digital intelligence,
              the speed of advancement is faster than any technology we’ve seen
              before. It's like going from a Model T Ford to a Tesla in five
              years.”
            </p>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2>The Road Ahead</h2>
            <h5>Three signposts</h5>
            <div className={styles.imgWrapper}>
              <StaticImage
                src="../images/ai-article/ai-chip-artificial-intelligence-future-technology-innovation.jpg"
                alt=""
                style={{ position: "absolute", height: "100%", width: "100%" }}
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <p>
              <strong>
                {" "}
                Whatever we will be driving, three signposts stand out.
              </strong>
            </p>

            <ul>
              <li>
                {" "}
                AI gains are real, but so are serious challenges to business
                models
              </li>
              <li>
                Both sectors will need new ways to show value, to hold audiences
                or justify fees
              </li>
              <li>Slow-movers risk losing business and staff.</li>
            </ul>

            <blockquote>
              As an industry, journalism will push harder for regulatory
              support. Public relations may need new ways of showing and
              charging for value or even farewell the hallowed billable hour.
            </blockquote>

            <p>
              Strikingly, respondents and interviewees were evenly divided in
              both sectors about whether or not they felt “well prepared” for
              AI.
              <br />
              <br />
              Only one person explicitly expressed concerns about the machines
              taking over. This fear may linger at the back of minds, but it’s
              seemingly not one people grapple with during their working day.
            </p>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2>Journalism</h2>
            <h5>
              Making gains where they can, media organisations are also girding
              for the future
            </h5>
            <p>
              <strong>Finding the sweet spot</strong>
            </p>

            <p>
              SINCE APRIL 2023 BusinessDesk has been a frontrunner in declaring
              AI-generated stories on its home-age. Most are stock exchange
              announcements which an inhouse ChatGPT-based tool, affectionally
              known as Bidi, can edit in 45 seconds rather than, as sometimes
              previously, as many minutes.
              <br />
              <br />
              Readers get faster updates, reporters more time to research or ask
              CEOs tough questions for follow-up stories, says Victoria Young,
              the publication’s editor.
              <br />
              <br />
              It’s a win: win that comes from being in a “sweet spot,” where
              subscribers want timely updates and are ready to pay for in-depth
              research. Outlets serving bigger audiences won’t have the same
              advantage. But they are also making gains.
              <br />
              <br />
              At NZME, which owns BusinessDesk, the New Zealand Herald, several
              radio stations and regional newspapers, Matt Martel, Managing
              Editor, Audience and Platform Curation, says they are using AI to
              “ free up reporters to spend more time getting good stories.”
            </p>

            <p>
              A newspaper executive when the internet first forced the closure
              of printing presses, Martel knows what a big new technology can
              do. The focus now, he says, is on process improvement with
              creative gains to come.
              <br />
              <br />
              Using a ChatGPT 3.5 tool, the company is using AI to slash editing
              times, undertake proofing jobs like checking te reo macrons, and
              match local and global content to style. Soon Herald readers will
              be able to have every story read to them out loud.
              <br />
              <br />
              Other targeted gains include faster content management and
              repurposing of some home page display. While humans continue to
              determine the top stories, he notes AI is free from unconscious
              editor bias in allocating content that appeals to them most.
              <br />
              <br />
              At Stuff, Joanna Norris, Managing Director Masthead Publishing,
              also cites positive early gains from AI. A trial “hyperlocal”
              Democracy AI tool, for example, scours previously dense council
              and official documents to deliver Waikato readers regular
              roundups.
            </p>

            <div className={styles.imgWrapper} style={{ marginTop: "50px" }}>
              <StaticImage
                src="../images/ai-article/still-life-books-versus-technology.jpg"
                alt=""
                style={{ position: "absolute", height: "100%", width: "100%" }}
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <p>
              <strong>Useless at 42</strong>
            </p>
            <p>
              Some observers even ponder a future where journalism enjoys a
              renaissance as audiences abandon oceans of automated content for a
              human voice. But most also see risks, with overseas experience
              pointing the way.
              <br />
              <br />
              A global January 2024 Reuters survey found only 47% of media
              leaders were confident about journalism’s prospects generally,
              with many feeling licensing deals with AI platforms would produce
              little useful money.
              <br />
              <br />
              A virtual journalist now produces 5% of copy for the tabloid,
              Express. de. Radio Expres in Slovakia has cloned the voice of a
              popular presenter so “she” can work day and night. Microsoft was
              an early mover in both replacing journalists on MSN.com and
              generating gaffes.
              <br />
              <br />
              Referencing a story on a young woman found dead, an AI poll
              invited readers to vote on whether she’d been a victim of murder,
              accident or suicide. When a former NBA player, Brandon Hunter,
              died unexpectedly the headline proclaimed him “Useless at 42.”
              <br />
              <br />
              News avoidance and news fatigue remain global worries. Bloodied by
              what they see as unfair competition from Google and Meta, media
              organisations now have a potentially more daunting challenge.
              <br />
              <br />
              Hyper-personalised content can deliver personalised news updates
              to customers tailored to their own preferences in content and
              style. Indeed, Martel has hypothesised a personalised home page
              populated only by its user’s four favourite stories. “And how, “
              he asks, “could anyone possibly monetise that?”
            </p>
            <blockquote>
              Referencing a story on a young woman found dead, an AI poll
              invited readers to vote on whether she’d been a victim of murder,
              accident or suicide. When a former NBA player, Brandon Hunter,
              died unexpectedly the headline proclaimed him “Useless at 42.”
            </blockquote>

            <h5>Pressing on</h5>
            <div className={styles.imgWrapper} style={{ marginTop: "50px" }}>
              <StaticImage
                src="../images/ai-article/shutterstock_239126896.jpg"
                alt=""
                style={{ position: "absolute", height: "100%", width: "100%" }}
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <p>
              Most media see copyright related regulatory support as vital, with
              varying optimism about ongoing government lobbying to address
              internet challenges.
              <br />
              <br />
              A related challenge is to local content. Regional journalists know
              about being swallowed by bigger players. Now this could happen to
              the country as a whole. Says Madeline Newman: “Who will tell our
              New Zealand stories? We risk losing our uniqueness and everything
              that goes with that.”
              <br />
              <br />
              Optimists hope audiences will value the home grown product,
              although sectors like fashion know that is no easy road. With so
              many balls in the air, Stuff’s Norris perhaps echoes a prevailing
              sentiment, by focusing on what’s in front of her and what can
              actually be changed.
              <br />
              <br />
              “You’ve got to keep working with what you have got,” she says,
              “And keep moving forward.”
            </p>
            <blockquote>
              “You’ve got to keep working with what you have got. And keep
              moving forward.”
              <small>
                - Joanna Norris, Stuff’s Managing Director Masthead Publishing
              </small>
            </blockquote>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2>Public Relations and Corporate Communications</h2>
            <h5>Disrupting how work gets done, and paid for</h5>
            <p>
              <strong>The end of the billable hour?</strong>
            </p>
            <p>
              This could become an everyday challenge: A client needs a
              communication strategy. But instead of asking for research and
              writing all they want is a quick review of a document produced by
              ChatGPT.
              <br />
              <br />
              Chris Galloway, an honorary research associate at Massey
              University and writer on communications and AI, sees the scenario
              as an opportunity for a skilled consultant to lead a considered
              review of the client’s options and best way forward.
              <br />
              <br />
              At One Plus One Communications, Max Burt, the agency’s General
              Manager, has already met a similar request, although that was for
              a writing assignment rather than a strategy, where the humans were
              able to show superior value.
            </p>
            <blockquote>
              “You want to feel like you have spotted the moment of change, but
              it doesn’t really work like that. It just creeps up on you and is
              the water we are swimming in.”
              <small>
                - Max Burt, General Manager at One Plus One Communications.
              </small>
            </blockquote>
            <p>
              Reflecting a trend in advertising, however, he can see a future
              where strategy or insight become mostly rewarded for the
              implementation work they attract. “If you’re in the type of
              consultancy where your work lives only on a piece of paper or
              slide deck then the wheels of progress are coming for you.”
              <br />
              <br />
              Agencies may need to look harder at the material outputs they are
              providing on the ground, and consider charging for outcomes rather
              than time. It could be the end of the billable hour.
              <br />
              <br />
              True, researching and writing a strategy can bring valuable
              thinking time. But work volumes and related fees are likely to
              fall.
              <br />
              <br />
              Burt is cautious about AI claims for overwhelming overnight
              change, but he is also in no doubt AI will be the biggest
              transformation we live through.
              <br />
              <br />
              “You want to feel like you have spotted the moment of change, but
              it doesn’t really work like that. It just creeps up on you and
              becomes the water we are swimming in,” he says.
            </p>

            <p>
              <strong>Jobs, credibility and trust</strong>
            </p>
            <p>
              Globally, January a Muck Rake survey found AI use had more than
              doubled among public relations professionals, from 28% to 64%, in
              the previous six months; with 89% of respondents saying it helped
              them work faster; 74% said their work was better as well.
              <br />
              <br />
              Concerns turned on likely impacts on jobs, credibility and trust.
              Some feared content creators would disappear, or that audiences
              would be overwhelmed in AI noise.
              <br />
              <br />
              Respondents also worried about an over-reliance on AI tools. One
              experiment by MIT researchers found most participants didn’t even
              bother checking AI’s output once it was created for them.
            </p>

            <blockquote>
              “If you don’t learn how to use it you’re going to become
              obsolete.”
              <small>
                - James Barnett, NZ Super Fund’s Senior Digital Communications
                Advisor
              </small>
            </blockquote>

            <p>
              Susanne Martin, the CEO of PRINZ, the Public Relations Institute
              of New Zealand, says PRINZ has been developing recommended
              policies for its members, based on declaring the extent of AI use
              and keeping humans in the loop. She says the touchstone is that
              “you should always declare your hand clearly to stakeholders.”
              <br />
              <br />
              Big change is more likely to come in big organisations, although
              perhaps not as fast as some AI enthusiasts would like.
              <br />
              <br />
              Catherine Etheredge, Head of Communications at the NZ Super Fund,
              sees an AI-produced annual report being turned around in hours as
              still some way off, given security, accuracy and regulatory
              concerns.
              <br />
              <br />
              But with AI playing an expanding role in managing financial data,
              she also sees it as helping meet a rising demand for content, with
              more organisations investing in talking directly with their
              stakeholders.
              <br />
              <br />
              James Barnett, the Fund’s Senior Digital Communications Advisor,
              was just back from an IABC (International Association of Business
              Communicators) conference in Chicago when interviewed and had seen
              how deeply embedded AI is already.
              <br />
              <br />
              “If you don’t learn how to use it,” he concluded, “you’re going to
              become obsolete.”
            </p>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2>The State of Play on Key Questions</h2>
            <h5>50/50 ready for AI</h5>

            <p>
              Feelings about AI naturally reflect each sector’s scale and
              priorities.
              <br />
              <br />
              Said one PR respondent: “my hope is that this will improve the
              quality of work from image creation for presentations to accurate
              fact-checking and basic grammar in written documents.”
              <br />
              <br />
              A media fear was: “Lazy and irresponsible use of AI by some
              organisations will undermine confidence in the media more widely.”
              <br />
              <br />
              Most organisations allow employees to use their own AI at work.
              Justin Flitter calls this BYOAI and says that if done without
              explicit approval it raises serious questions about security,
              privacy and intellectual property.
              <br />
              <br />
              All respondents had an AI policy and an internal AI leader.
            </p>
            <p>
              <strong>Embracing AI</strong>
            </p>

            <ul>
              <li>
                On a daily basis PR practitioners were more likely to use AI
                “always” or “often;” journalists more frequently saying “rarely”
              </li>
              <li>
                While bigger media companies are using bespoke AI solutions
                others provide access to third-party solutions only
              </li>
              <li>
                Investment in both sectors appears to remain moderate, with most
                between $5000 and $50,000
              </li>
              <li>
                Tailored, secure solutions appear more common in PR; one media
                organisation reported no organisational use of AI
              </li>
              <li>
                PR Respondents were more likely to expect AI to fundamentally
                change what they do; journalists more likely to see it as a
                useful tool but not bringing fundamental change
              </li>
              <li>
                Both sectors were split 50/50 about feeling well prepared for
                AI’s impact.
              </li>
            </ul>
            <p>
              <strong>Hopes and fears</strong>
            </p>
            <ul>
              <li> Hopes in both industries turn on greater efficiencies</li>
              <li>
                PR practitioners look forward to new ways to do things,
                journalists to more time for quality research and interviews
              </li>
              <li>
                Both fear loss of confidence, trust and some job losses,
                although overwhelmingly AI is seen as an enabler not a replacer.
              </li>
            </ul>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2>Related Sectors Showing the Way</h2>
            <h5>Coming ready or not</h5>
            <div className={styles.imgWrapper}>
              <StaticImage
                src="../images/ai-article/u4713236192_lightspeed_technology_--v_6.1_af56296a-1511-4411-a5f2-530fd714d45e_3.png"
                alt=""
                style={{ position: "absolute", height: "100%", width: "100%" }}
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <p>
              <strong>Businesses like ours didn’t exist till last year</strong>
            </p>
            <p>
              A travel company cuts a customer follow-up process from two hours
              to 30 seconds. An engineering firm slashes field report writing
              from a day to minutes.
              <br />
              <br />
              “Businesses like ours did not exist till last year,” says Dave
              Howden, CEO and co-founder of Auckland-based SupaHuman.ai.
              <br />
              <br />
              Yet having launched what he describes as the country’s first and
              possibly still only specialist AI firm in January his team of 25
              staff, who carry PhDs in AI engineering, are now busy radically
              chopping expectations of just how long traditional tasks should
              take.
              <br />
              <br />
              AI’s power, he says, lies in moving on from the tedium of process
              to unearthing the “gold” of the insights customers were wanting in
              the first place.
              <br />
              <br />
              But to make these gains you need to configure a purpose-trained
              tool with full audit and transparency across your business.
              Otherwise about 95% of the real value accessible from the likes of
              ChatGPT are left on the table.
              <br />
              <br />
              Costs for a medium-sized highly regulated business range from
              $20,000 to $30,000. But for those with the right need the returns
              are significant and fast.
              <br />
              <br />
              “Configuring and engineering algorithms,” he says, “can create the
              best employee you could possibly have.”
            </p>

            <blockquote>
              “To make these gains you need to configure a purpose-trained tool
              with full audit and transparency across your business.”
              <small>- Dave Howden, CEO SupaHuman.ai.</small>
            </blockquote>

            <h5>Irresistible allure</h5>
            <div className={styles.imgWrapper}>
              <StaticImage
                src="../images/ai-article/u4713236192_Artist_painting_a_picture_with_the_help_of_a_robo_3a598eb1-f504-41bd-bd01-c734db0faf93_0.png"
                alt=""
                style={{ position: "absolute", height: "100%", width: "100%" }}
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <p>
              “I don’t know where it’s going but I think it’s pretty cool and
              I’m just getting on board and seeing where it takes me.”
              <br />
              <br />
              So far for Steve Ballantyne, founder of Auckland’s Brand-IQ, the
              AI ride has been rewarding.
              <br />
              <br />
              Using ChatGPT, Midjourney and Dalle, he is making productivity
              gains of 200% to 300% in image production.
              <br />
              <br />
              “Now you can be a startup with very limited budget and still do
              world-class work to the same quality as Madison Ave,” he says.
              <br />
              <br />
              Time needed for ideas creation, strategy and image production has
              dropped from days to hours. Clients get to see concepts faster.
              Pitches are at a new level.
              <br />
              <br />
              Everything still needs the human touch, he says. But likening AI
              to a creative spark, he finds it has an irresistible allure,
              adding an extra layer of inspiration for designers.
              <br />
              <br />
              “No model fees, no photography fees, no travel costs, no
              time-consuming photo shoots, no clichéd stock imagery. And a swift
              turnaround.”
            </p>
            <blockquote>
              “Now you can be a startup and still do world-class work to the
              same quality as Madison Ave.”
              <small>- Steve Ballantyne, founder of Auckland’s Brand-IQ,</small>
            </blockquote>
          </div>
        </section>

        <section className={styles.info}>
          <div className={styles.container}>
            <h2 style={{ marginBottom: "50px" }}>A last word</h2>
            <h5>On the cusp of a very new future</h5>
            <div className={styles.imgWrapper}>
              <StaticImage
                src="../images/ai-article/u4713236192_humans_and_ai_working_togther_--v_6.1_77752706-2d81-4d69-a899-2fae058a6b7a_0.png"
                alt=""
                style={{ position: "absolute", height: "100%", width: "100%" }}
                objectFit="cover"
                objectPosition="center"
              />
            </div>

            <p>
              This report started with structured questions and formal
              interviews, and very soon shifted to capturing a human snapshot.
              Interviewees are developing AI strategies, but most of these are
              necessarily new and open-ended. Rather than masterplans, what
              emerges is a sense of a shared moment on the cusp of a very new
              future.
              <br />
              <br />
              Globally, some experts worry about what a new super intelligence
              surpassing humans might bring. Others predict a slowdown in AI,
              saying huge breakthroughs will slow or cost too much. Meanwhile AI
              continues to pull impressive rabbits out of its hat.
              <br />
              <br />
              In a recent Boston experiment, for example, ChatGPT outperformed
              doctors in diagnosing a medical condition from patient records –
              and did even better when left to work without human input. As
              Ethan Mollick recently wrote: “Every week it seems like AI serves
              up a new miracle.”
              <br />
              <br />
              Most experts and interviewees believe AI will bring many more
              opportunities than challenges. If so, success may depend as much
              on humans, and our ability to adapt, as on the technology itself.
            </p>
            <blockquote>
              “Every week it seems like AI serves up a new miracle.”
              <small>- AI expert and writer Ethan Mollick</small>
            </blockquote>
          </div>
        </section>

        <section className={styles.last}>
          <div className={styles.container}>
            <h2>About this report</h2>
            <h5>
              Sincere thanks to the sector participants who generously provided
              time and insight for this report.
            </h5>
            <p>
              Media interviewees and respondents represented organisations
              serving over half of all audiences reached in New Zealand. PR and
              communication respondents included larger agencies, sector
              commentators and internal communication teams. Research was based
              on 14 interviews between June and October 2024 and an online
              questionnaire bringing total respondents to 20, supported by
              ongoing and informal input. Robert Mannion has a lifelong career
              working in journalism and public relations and runs Mannion
              Consulting.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default GettingPastTomorrow;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
